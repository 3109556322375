<template>
  <div class="navbar">
    <v-app-bar fixed :color="color"  elevate-on-scroll  height="100%" width="100%" class="md-0">   
      <!-- LOGO -->
      <div class="d-flex align-center" >
        <router-link to="/">
            <v-img
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              width="5rem"
              @click="$vuetify.goTo('#home')"
            />
        </router-link>
      </div>

      <!-- BTNS -->
      <div class=" btn-container align-center d-none d-md-flex" >
          <div  v-for="(btn, index) in btns" :key="index"> 
            <div v-if="btn.title === 'Tienda'">
              <v-btn :href="btn.linkTo" class="menu-btns ml-2 " text rounded large plain> 
                <span class="black--text font-weight-light">{{btn.title}}</span>
              </v-btn>
            </div>
            <div v-else>
              <v-btn @click="$vuetify.goTo(btn.linkTo)" class="menu-btns ml-2 font-weight-regular" text rounded large plain> 
                <span class="black--text font-weight-light ">{{btn.title}}</span>
              </v-btn>
            </div>
          </div>
  
      </div>
      
      <v-spacer></v-spacer>
      <!-- SOCIALS -->
      <div class=" align-center d-none d-md-flex" >
        <a v-for="(btn, index) in socials" :key="index"  :href="btn.linkTo" target="_blank">
            <v-btn text class="ml-2 mr-5" icon x-large > 
              <v-icon color="#4A86AF">{{btn.icon}}</v-icon>
            </v-btn>
        </a>
      </div>
      
      <!-- RESPONSIVE MENU BUTTON-->
      <v-app-bar-nav-icon class="black--text d-xs-flex d-md-none mr-5" @click="showMenu"></v-app-bar-nav-icon>
    </v-app-bar>

    <!-- NAVIGATION DRAWER -->
      <v-navigation-drawer   v-model="drawer" fixed class="d-xs-flex d-md-none" width="100%" color="#63A8D3">
        <v-btn  rounded icon color="black" class="mt-4 ml-3" @click="showMenu" x-large>X</v-btn>
        <v-list class="d-flex flex-column align-center justify-center drawer-btns" height="50%"> 
          <v-list-item v-for="(btn, index) in btns" :key="index" class="mb-5">
            <v-btn v-if="btn.title === 'Tienda' " text class="white--text" x-large :href="btn.linkTo" @click="!drawer" >{{btn.title}}</v-btn>
            <v-btn v-else text class="white--text" x-large @click="$vuetify.goTo(btn.linkTo), drawer=false" >{{btn.title}}</v-btn>
          </v-list-item>
          <!-- SOCIALS(DRAWER) -->
          <v-list class="d-flex">
            <v-list-item v-for="(btn, index) in socials" :key="index" >
                <a :href="btn.linkTo" target="_blank">
                  <v-btn plain icon large > 
                    <v-icon color="white">{{btn.icon}}</v-icon>
                  </v-btn>
                </a>
            </v-list-item>
          </v-list>

        </v-list>
  
      </v-navigation-drawer>
  </div>
  
</template>

<script>
export default {
    name: 'Navbar',
    props:{
        color: {
            type: String,
            default: 'primary'
        },
    }, 
    data(){
      return{
        btns: [
            {title: 'Inicio', linkTo:'#home'},
            {title: 'Nosotros', linkTo:'#nosotros'},
            {title: 'Servicios', linkTo:'#Services'},
            {title: 'Tienda', linkTo:'https://shop.mapisapty.com/'},
            {title: 'Contacto', linkTo:'#Contact'},
        ],
        socials: [
          {icon: 'mdi-instagram', linkTo:'https://www.instagram.com/mapisa507/?hl=es-la'},
          {icon: 'mdi-facebook', linkTo:'https://www.facebook.com/mantenimientosdepiscina'},
        ],
        drawer: false
      }
    },
    methods: {
      showMenu(){
        this.drawer = !this.drawer;
      },
    }
  
    
}
</script>

<style scoped lang="scss">
  a{
    text-decoration: none;
  }
  
  .drawer-btns{
    margin-top: 3rem;
  }
  
  
</style>