<template>
  <div class="home">
    <!-- COMPONENTS -->
    <Navbar color="white"/>
    <Cta/>
    <About/>
    <Services/>
    <Contact/>
    <FooterComponent />
    <!-- FLOATING-BTN -->
    <div class="float-btn">
        <a href="https://web.whatsapp.com/send?l=es&phone=+50766764054" target="_blank">
          <v-btn
            color ="rgb(27, 215, 65)" 
            absolute
            fab
            fixed
            x-large
            bottom
            right
            class="whatsapp-btn d-none d-sm-block"
          >
            <v-icon color="white">mdi-whatsapp</v-icon>
          </v-btn>
        </a>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import Cta from "@/components/home_components/Cta"
import Navbar from "@/components/home_components/Navbar"
import About from "@/components/home_components/About"
import Services from "@/components/home_components/Services"
import Contact from "@/components/home_components/Contact"
import FooterComponent from  "@/components/Tienda/Footer.vue";

export default {
  components: {Cta, Navbar, About, Services, FooterComponent, Contact }
}
</script>

<style lang="scss" scoped>

.whatsapp-btn{
  margin: 0 1rem 8rem 0;
}
.main-page{
  width: 100%;
}


</style>
