<template>
  <div id="Contact" class="mx-0">
    <!-- SHAPE -->
    <!-- <div class="custom-shape-divider-top-1626668177 pa-0">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
    </div> -->
    <!-- END OF SHAPE -->
    <v-container id="contact-container" fluid class="mx-0 ">
        <h3 class="text-center text-h4 text-md-h3 font-weight-light white--text title pb-5">
          Contactanos
        </h3>
        <v-row class=" d-flex align-center ma-0 " >
          <v-col cols="12" md="6" class="pa-5 align-self-center">
            <v-card>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3940.321484006175!2d-79.497103!3d9.03441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x684028967ad0c307!2sMantenimieto%20De%20Piscinas!5e0!3m2!1ses-419!2spa!4v1623097350559!5m2!1ses-419!2spa" width="100%" :height="$vuetify.breakpoint.mdAndUp ? '450' : '300' " style="border:0;" allowfullscreen="" loading="lazy">
                  </iframe>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
              <v-row class="mx-0">
                  <v-col cols="12" class="text-center mx-0">
                      <v-card class="pa-5 d-flex align-center flex-column" max-heigth="50rem">
                        <h3 class= "text-md-h4 pb-5">
                          <v-icon color="red" :x-large="$vuetify.breakpoint.mdAndUp">mdi-map-marker-outline</v-icon>
                          Ubicación
                        </h3>
                        <v-row class="text-center  pa-0"> 
                            <v-col cols="12" md="6">
                                <v-sheet class="mx-0 ">
                                    <h4 class=" caption  text-md-h5" :class="$vuetify.breakpoint.smAndDown ? 'font-weight-bold' : '' ">Panamá, Panamá</h4>
                                    <p class="caption text-md-subtitle-1">
                                      San Miguelito, <br>
                                      Mateo Iturralde <br>
                                      Calle San Martin local 14345
                                    </p>
                                </v-sheet>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-sheet>
                                    <h4 class="text-md-h5 caption" :class="$vuetify.breakpoint.smAndDown ? 'font-weight-bold' : '' ">Panamá Oeste, Panamá</h4>
                                    <p class="caption text-md-subtitle-1">
                                      Chame, <br>
                                      Vía Panamericana, <br>Coronado  
                                      frente a Comasa 
                                    </p>
                                </v-sheet>
                            </v-col>
                        </v-row>
                      </v-card>
                  </v-col>
                  <v-col cols="12" class="pa-0 mx-0 d-flex justify-center">
                      <v-row class="mx-0 pa-0  justify-center" dense>
                        <v-col cols="11" md="4" sm="4" >
                            <v-card class="text-center pa-3 d-flex flex-column justify-center" max-height="20rem" height="12rem">
                              <v-icon color="green" :x-large="$vuetify.breakpoint.mdAndUp">
                                mdi-whatsapp
                              </v-icon>
                               <h4 class="text-md-h6">whatsapp</h4>
                               <p>(+507) 66764054</p>
                            </v-card>
                        </v-col>
                        <v-col cols="11" md="4" sm="4" >
                            <v-card class="text-center pa-3 d-flex flex-column justify-center" max-height="20rem" height="12rem">
                              <v-icon color="blue" :x-large="$vuetify.breakpoint.mdAndUp">
                                mdi-phone
                              </v-icon>
                               <h4 class="text-md-h6 ">Telefonos</h4>
                               <p class="text-body-2">
                                 (+507) 267-1516 Panamá <br>
                                 (+507) 240-6128 Chame
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="11" md="4" sm="4">
                            <v-card class="text-center pa-3 d-flex flex-column justify-center" max-height="20rem" height="12rem">
                              <v-icon color="primary" :x-large="$vuetify.breakpoint.mdAndUp">
                                mdi-email
                              </v-icon>
                               <h4 class="text-md-h6">Correo</h4>
                               <p>info@mapisa.com</p>
                            </v-card>
                        </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
        </v-row>
    
    </v-container>  
  </div>
</template>
<script>
export default {
  name: "Contact",
};
</script>
<style lang="scss" scoped>
#Contact {
  padding:  3rem 0;
  padding-bottom: 10rem;
  background: #8262517c;
  .custom-shape-divider-top-1626668177 .shape-fill {
      fill: #FFFFFF;
  }
  .title{
    text-shadow: 2px 2px 2px  rgba(0, 0, 0, 0.431);
  }

}
</style>
