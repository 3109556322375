<template >
    <div id="home"> 
        <!-- SLIDER -->
        <v-carousel
            show-arrows-on-hover    
            delimiter-icon="mdi-minus"
            hide-delimiter-background
            cycle
            :height="$vuetify.breakpoint.mdAndUp ? '80vh': '70vh'"
            id="carousel"
        >
            <v-carousel-item 
                v-for="(item, i) in carouselItems" 
                :key="i"
                :src="item.src"
            >
                <v-container class="d-flex flex-column justify-center align-center cta-container" fluid>
                    <h1 class="text-h4 text-sm-h3 text-md-h2 text-xl-h1 font-italic   text-center text-weight-bold" id="carousel-text">{{item.text}}</h1>
                    <v-btn  :x-large="$vuetify.breakpoint.mdAndUp" small color="#295A93" @click="$vuetify.goTo(item.linkTo)" class="mt-5" rounded>Conoce más</v-btn>
                </v-container>
            </v-carousel-item>
            
        </v-carousel>
    </div>
</template>
<script>
export default {
    name: 'Cta',
    data(){
        return{
            carouselItems: [
                {src: require('@/assets/img-store/p1.jpg'), text:'Especialistas en Mantenimiento de Piscinas', linkTo: '#nosotros'},
                {src: require('@/assets/img-store/p2.jpg'), text:'Mantenimiento, construcción, reparación de piscinas y jacuzzis', linkTo:'#Services'},
                {src: require('@/assets/img-store/p3.jpg'), text:'Venta de accesorios y repuestos'},
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    #carousel{
        margin-top: 6rem;
    }
    .cta-container{
        height: 100%;
        h1{
            text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.178);
        }
        background: rgba(0, 0, 0, 0.315);
    }
    #carousel-text{
        width: 80%;
    }
    
</style>

