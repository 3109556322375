<template>
  <v-footer  
    class="mx-0 pa-0"
  >
      <v-row  
        class="mx-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'md-padding' : 'py-10' "
      >
          <v-col
            cols="12" md="3"
            :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "
           
          >
            <v-img
              src="@/assets/logo.png"
              width="8rem"
              class="pt-5 mx-0"
              
            />
            <p
              class="text-subtitle-1 font-weight-light"
              :class="$vuetify.breakpoint.smAndUp ? 'pr-15' : 'px-8' "

            >
              Mapisa empresa dedicada a la Construcción, Mantenimiento, Reparación e Iluminación de Piscinas y Jacuzzi
            </p>
            <div class="icons ">
              <a href="https://www.facebook.com/mantenimientosdepiscina" target="_blank">
                <v-btn
                  icon
                  small
                  color="gray"
                >
                  <v-icon>
                      mdi-facebook
                  </v-icon>
                </v-btn>
              </a>
              <a href="https://web.whatsapp.com/send?l=es&phone=+50766764054" target="_blank">
                <v-btn
                  icon
                  small
                  color="gray"
                  class="ml-4"
                >
                  <v-icon>
                      mdi-whatsapp
                  </v-icon>
                </v-btn>
              </a>
              <a href="https://www.instagram.com/mapisapty/" target="_blank">
                <v-btn
                  icon
                  small
                  color="gray"
                  class="ml-4"
                >
                  <v-icon>
                      mdi-instagram
                  </v-icon>
                </v-btn>
              </a>
            </div>

          </v-col>
          <v-col
            cols="12" md="3"
            :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "

          >
            <h4
              class="text-h5"
              :class="$vuetify.breakpoint.mdAndUp ? 'ml-7' : '' "
            >
              Menú  
            </h4>
            <v-list
              :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "
              color="transparent"

            >
                <v-list-item>
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        @click="$vuetify.goTo('#home')"
                        class="font-weight-light"


                      >
                        Inicio
                      </v-btn>
                </v-list-item>
                <v-list-item>
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        @click="$vuetify.goTo('#nosotros')"
                        class="font-weight-light"

                      >
                        Nosotros
                      </v-btn>
                </v-list-item>
                <v-list-item>
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        @click="$vuetify.goTo('#Services')"
                        class="font-weight-light"

                      >
                        Servicios  
                      </v-btn>
                </v-list-item>
                <v-list-item>
                    <a href="https://shop.mapisapty.com/">
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        class="font-weight-light"

                      >
                        Tienda
                      </v-btn>
                    </a>
                </v-list-item>
                <v-list-item>
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        @click="$vuetify.goTo('#Contact')"
                        class="font-weight-light"

                      >
                        Contacto
                      </v-btn>
                </v-list-item>
                
            </v-list>
          </v-col>
          
          <v-col
            cols="12" md="3"
            :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "

          >
            <h4
              class="text-h5"
              :class="$vuetify.breakpoint.mdAndUp ? 'ml-7' : '' "
            >
              Información Util
            </h4>
            <v-list
              :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "
              color="transparent"

            >
                <v-list-item>
                    <a href="">
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        class="font-weight-light"
                      >
                        Metodos de pago
                      </v-btn>
                    </a>
                </v-list-item>
                <!-- <v-list-item>
                    <a href="">
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"

                      >
                        Lorem, ipsum.
                      </v-btn>
                    </a>
                </v-list-item>
                <v-list-item>
                    <a href="">
                      <v-btn
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        text
                      >
                        Lorem, ipsum.
                      </v-btn>
                    </a>
                </v-list-item>
                <v-list-item>
                    <a href="">
                      <v-btn
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        text
                      >
                        Lorem, ipsum.
                      </v-btn>
                    </a>
                </v-list-item> -->
            </v-list>
          </v-col>

          <v-col
            cols="12" md="3"
            :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "
          >
            <h4
              class="text-h5"
              :class="$vuetify.breakpoint.mdAndUp ? 'ml-7' : '' "
            >
              Mi Cuenta
            </h4>
            <v-list
              :class="$vuetify.breakpoint.xs ? 'd-flex flex-column align-center' : '' "
              color="transparent"
            >
                <v-list-item>
                    <a href="https://shop.mapisapty.com/pages/login">
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        class="font-weight-light"

                      >
                        Iniciar Sesión
                      </v-btn>
                    </a>
                </v-list-item>
                <v-list-item>
                    <a href="https://shop.mapisapty.com/pages/login">
                      <v-btn
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        class="font-weight-light"
                      >
                        Registrarse
                      </v-btn>
                    </a>
                </v-list-item>
                <!-- <v-list-item>
                    <a href="">
                      <v-btn
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        text
                      >
                        Lorem, ipsum.
                      </v-btn>
                    </a>
                </v-list-item>
                <v-list-item>
                    <a href="">
                      <v-btn
                        :x-small="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.mdAndUp"
                        
                        text
                      >
                        Lorem, ipsum.
                      </v-btn> 
                   </a>
                </v-list-item> -->
            </v-list>
          </v-col>
      </v-row>
          <div class="line text-center pa-2">
            <h6 class="text-caption text-md-subtitle-1 font-weight-light ">
              Desarrollado por  
              <a href="https://www.innuvex.com/" target="_blank">
              Innuvex.</a>
            </h6>
          </div>
      
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
  a{
    text-decoration: none;
  }
   .md-padding {
     padding: 5rem 8rem;
   }
   .line{
     width: 100%;
     background-color: #bcdbff;
   }
</style>

