<template>
    <div id="Services">
        <h2 class="text-center mt-10 px-15 pt-10 font-weight-light font-italic text-h4 text-md-h2" >SERVICIOS</h2>
        <v-container id="row-container" class="mx-auto" >
            <v-row  id="services-row" class="d-flex">
                <v-col cols="12" md="4" v-for="(service, i) in services" :key="i" class="mt-15 d-flex flex-column align-center" :class="$vuetify.breakpoint.smOnly ? 'd-flex justify-center' : '' ">
                    <v-card width="30rem" height="auto" rounded color="#DAE3EF" v-scroll-reveal="{delay: service.delay}" id="card">
                        <v-card-title class="text-subtitle-2 text-md-h6"> 
                            {{service.title}}
                        </v-card-title>
                        <v-img :src="service.img" width="100%" height="20rem"/>
                        <v-card-actions>
                            <v-btn text @click="reveal(i)">Leer Más <v-icon :class="service.reveal ?'icon-rotate': ''" >mdi-chevron-right</v-icon></v-btn>
                        </v-card-actions>
                        <v-expand-transition>
                            <v-card v-if="service.reveal" id="reveal-card">
                                <v-card-text id="reveal-text">
                                    {{service.info}}
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'Services',
    data(){
        return{
            services: [
                {title:'PISCINAS Y JACUZZI', info: 'Nuestras  piscinas son construidas de forma Monolítica (concreto lanzado)      Diseñamos, construimos Piscinas y Jacuzzi  en variedades de diseños: (Riñón, Rectangular, Cuadrada otras)  construidas con las mejores técnicas del mercado.', img: require('@/assets/img-store/service1.jpg'), delay: 500, reveal: false},

                {title:'REVESTIMIENTO', info: 'El revestimiento interno de la piscina  varía entre los colorines (vidripool, cerámica otros) o el Hydrazzo producto decorativo impermeabilizante  que no requiere  pintura, de alta resistencia y con una gama de colores a escoger.Revestimos terrazas y aceras, alrededor de la piscina con la técnica de Spray Deck, piso frio anti resbalante, resistente al calor y humedad.',img: require('@/assets/img-store/service2.jpg'), delay: 550, reveal: false},  

                {title:'INSTALACION DE LUCES LED', info: 'Para su piscina o jacuzzi iluminación con la tecnología LED, pueden transformar  las agradables tardes alrededor de la piscina en mágicas noches Esto se debe a que proporcionamos calidad y confiabilidad, el consumo  trabaja a baja tensión, dentro de los límites de seguridad de 12 voltios en corriente alterna. Elija entre deslumbrantes luces que cambian de color, luces estándar incandescentes para lograr efectos bajo el agua, y fibras ópticas para el perímetro. ', img: require('@/assets/img-store/service3.jpg'), delay: 600, reveal: false},  

                {title:'SISTEMA DE FILTRADO', info: 'El cliente puede elegir  entre los  diferentes  tipos de  Filtro: de Tierra de diatomeas o de Arena. PENTAIR, HAYWAR JACUZZILos Filtros de tierra de diatomeas,  La tierra diatomeas es una sustancia que recogen la suciedad y los escombros, el polvo e incluso algunas bacterias, la cual permite que los períodos entre limpiezas sean más prolongados. Los filtros de arena  son simples, y efectivos  para filtrar el agua, requieren muy poca atención. El agua es dirigida a través de un recipiente a presión lleno de arena. La suciedad se acumula en los espacios  entre las partículas de arena, lo que hace aumente la presión del recipienteNuestras  piscinas son construidas de forma Monolítica (concreto lanzado)      Diseñamos, construimos Piscinas y Jacuzzi  en variedades de diseños: (Riñón, Rectangular, Cuadrada otras)  construidas con las mejores técnicas del mercado.', img: require('@/assets/img-store/service4.jpg'), delay:650, reveal: false},  

                {title:'BOMBAS', info: 'Tenemos lo último en bombas para Piscina, Spa o Jacuzzi diseñadas con las  más altas tecnología que facilitan el flujo del agua.PENTAIR, HYWARD, JACUZZI otras', img: require('@/assets/img-store/service5.jpg'), delay: 700, reveal: false},  

                {title:'EQUIPOS Y ACCESORIOS', info: 'Tenemos lo último en bombas para Piscina, Spa o Jacuzzi diseñadas con las  más altas tecnología que facilitan el flujo del agua.PENTAIR, HYWARD, JACUZZI otras', img: require('@/assets/img-store/service6.jpg'), delay: 700, reveal: false},

                ],
        }
    },
    methods: {
        reveal(i){
            const serviceFiltered = this.services.filter(service => service != this.services[i])
            console.log(serviceFiltered)
            this.services[i].reveal = !this.services[i].reveal
            if(this.services[i].reveal == true){
                serviceFiltered.forEach(service =>{
                    service.reveal = false
                })
            }
        
        }
    }
}
</script>
<style lang="scss">
    #Services{
        padding-bottom: 3rem;
    }
    #card{
        position: relative;
    }
    .icon-rotate{
        transform: rotate(90deg);
        transition: transform 0.4s ease;
    }
  
</style>