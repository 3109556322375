<template>
  <div id="nosotros" v-scroll-reveal="{ delay: 250 }">
    <!-- RESUMEN -->
    <div
      id="mapisa-info"
      class="mx-auto d-flex align-center"
      :class="$vuetify.breakpoint.mdAndUp ? 'width' : ''"
    >
      <v-container id="mapisa-info--content">
        <!-- MAPISA-INFO-TITLE -->
        <h1
          class="text-center font-italic font-weight-medium text-h4 text-md-h2"
          :class="$vuetify.breakpoint.xs ? 'text-h5' : ''"
        >
          Mantenemos tu piscina siempre limpia e impecable
        </h1>
        <!-- MAPISA-INFO-TEXT -->
        <p
          class="mt-5 text-center text-xs-caption2 text-md-h5 font-weight-light "
        >
          Mapisa empresa dedicada a la Construcción, Mantenimiento, Reparación e
          Iluminación de Piscinas y Jacuzzi con la tecnología
          LED, Ventas de Químicos y Accesorios para piscinas., Nos ajustamos a
          su presupuesto.
        </p>
      </v-container>
    </div>
    <!-- ABOUT-TITLE -->
    <!-- <h2 class="text-center py-15 text-h4 text-uppercase blue lighten-5">
      Sobre mapisa
    </h2> -->
    <v-container fluid class="py-0 mx-auto">
      <!-- MISION & VISION -->
      <v-row id="about">
        <v-col
          cols="12"
          sm="6"
          v-for="(info, i) in aboutInfo"
          :key="i"
          class="pa-0"
        >
          <v-sheet
            v-scroll-reveal="{ delay: info.delay }"
            :color="info.color"
            height="100%"
            class="d-flex flex-column justify-center "
            :class="$vuetify.breakpoint.mdAndUp ? 'about-text' : 'pa-15'"
          >
            <h2
              :class="info.textColor"
              class="text-h2 mb-5 font-italic about-title"
            >
              {{ info.title }}
            </h2>
            <p :class="info.textColor">{{ info.text }}</p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      aboutInfo: [
        {
          title: "Misión",
          text:
            "Brindarle a nuestra distinguida clientela, los adelantos tecnológicos que nos permitan ofrecer: Calidad de servicios y productos, más funcionalidad de sus piscinas, mejor calidad en el agua.",
          color: "#dae3ef",
          textColor: "black--text",
          delay: 300,
        },
        {
          title: "Visión",
          text:
            "Ser una empresa sólida, segura y confiable comercializadora de productos y servicios, ofreciendo calidad a nuestros clientes, con un personal altamente capacitado, que genere compromiso y esfuerzo.",
          color: "#4F8EB3",
          textColor: "white--text",
          delay: 500,
        },
      ],
    };
  },
};
</script>
<style lang="scss">

#nosotros{
  padding-bottom: 3rem;
}
.about-text {
  padding: 4.5rem 6rem;
}

#mapisa-info {
  #mapisa-info--content {
    padding: 10rem 1rem;
    h1 {
      color: rgb(139, 205, 229);
    }
  }
}
.width {
  width: 70%;
}
</style>
